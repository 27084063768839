import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainScreen from 'screens/MainScreen/MainScreen';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import BusinessScreen from 'screens/BusinessScreen/BusinessScreen';
import InvestorScreen from 'screens/InvestorScreen/InvestorScreen';
import BrokersScreen from 'screens/BrokersScreen/BrokersScreen';
import UserAgreementScreen from 'screens/UserAgreementScreen/UserAgreementScreen';
import { PublicAgreementScreen } from 'screens/PublicAgreementScreen/PublicAgreementScreen';
import RiskAgreementScreen from 'screens/RiskAgreementScreen/RiskAgreementScreen';
import TariffScreen from 'screens/TariffScreen/TariffScreen';
import { ModalGifts } from 'components/ModalGifts';

function App() {
  return (
    <div>
      <Header />
      <ModalGifts />

      <Routes>
        <Route path='/' element={<MainScreen />} />
        <Route path='/business' element={<BusinessScreen />} />
        <Route path='/investors' element={<InvestorScreen />} />
        <Route path='/brokers' element={<BrokersScreen />} />
        <Route path='user_agreement' element={<UserAgreementScreen />} />
        <Route path='public_agreement' element={<PublicAgreementScreen />} />
        <Route path='risk_agreement' element={<RiskAgreementScreen />} />
        <Route path='tariff' element={<TariffScreen />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
