import Container from 'index.style';
import React, { useState } from 'react';
import {
  BlockContact,
  ErrorMessage,
  FeedbackBlockBox,
  InputCont,
  InputMaskCustom,
  InputMaskCustomBox,
  LeftBlock,
  LeftBlockContact,
  LeftBlockText,
  LeftBox,
  RightBlock,
  RightBlockDesc,
  RightBlockDescTwo,
  RightBlockForm,
  RightBlockHeader,
  TextareaCustom,
} from './FeedbackBlock.style';
import { Button } from 'components/common/Button';
import { isValidPhone } from 'utils/isValidPhone';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { Input } from 'components/common/Input';
import IconEmail from 'assets/img/IconEmail.png';
import IconPhone from 'assets/img/IconPhone.png';
import { TitleInput } from 'components/common/Input/Input.style';

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Функция для отправки формы на нужный эндпоинт
async function sendFeedbackApi(payload: {
  email: string;
  phone: string;
  name: string;
  message: string;
}) {
  return await axios.post('https://lend.kz/api/v1/landing/send/feedback', payload, {
    withCredentials: true,
  });
}

export const FeedbackBlock = () => {
  const { t } = useTranslation(); // Функция для получения переводов

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [inputErrors, setInputErrors] = useState({
    nameError: '',
    phoneError: '',
    emailError: '',
    messageError: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const validateForm = (name: string, phone: string, email: string, message: string) => {
    const isNameValid = name.trim() !== '';
    const isMessageValid = message.trim() !== '';
    const isPhoneValid = isValidPhone(phone);
    const isEmailValid = isValidEmail(email);

    const errors = {
      nameError: isNameValid ? '' : t('feedbackBlock.errors.invalidFormat'),
      phoneError: isPhoneValid ? '' : t('feedbackBlock.errors.invalidFormat'),
      emailError: isEmailValid ? '' : t('feedbackBlock.errors.invalidFormat'),
      messageError: isMessageValid ? '' : t('feedbackBlock.errors.invalidFormat'),
    };

    return { isValid: isNameValid && isMessageValid && isPhoneValid && isEmailValid, errors };
  };

  const formatPhone = (phone: string) => phone.replace(/\D/g, '');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(event.target.value);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);
    const isPhoneValid = isValidPhone(value);
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      phoneError: isPhoneValid ? '' : t('feedbackBlock.errors.invalidFormat'),
    }));
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);

    const isEmailValidValue = isValidEmail(value);
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      emailError: isEmailValidValue ? '' : t('feedbackBlock.errors.invalidFormat'),
    }));
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { isValid, errors } = validateForm(name, phone, email, message);
    setInputErrors(errors);

    if (isValid) {
      const formattedPhone = formatPhone(phone);
      const payload = {
        name,
        email,
        message,
        phone: formattedPhone,
      };

      try {
        const response = await sendFeedbackApi(payload);
        if (response.status === 200) {
          setIsFormSubmitted(true);
        } else {
          console.log(`Unexpected status code: ${response.status}`);
        }
      } catch (error) {
        console.error('An error occurred while sending the form:', error);
      }
    }
  };

  const handleRetry = () => {
    setName('');
    setPhone('');
    setEmail('');
    setMessage('');
    setInputErrors({
      nameError: '',
      phoneError: '',
      emailError: '',
      messageError: '',
    });
    setIsFormSubmitted(false);
  };

  return (
    <Container>
      <FeedbackBlockBox>
        <LeftBox>
          <LeftBlock>
            <LeftBlockText>
              <h3>{t('feedbackBlock.leftBlock.title')}</h3>
              <p>{t('feedbackBlock.leftBlock.paragraph')}</p>
            </LeftBlockText>
            <a href='https://cabinet.lend.kz/login' rel='noopener noreferrer'>
              <Button text={t('feedbackBlock.leftBlock.registerButton')} className='white1' />
            </a>
          </LeftBlock>
          <LeftBlockContact>
            <BlockContact>
              <img src={IconEmail} alt='iconEmail' />
              <div>
                <h5>{t('feedbackBlock.contacts.emailTitle')}</h5>
                <a href={`mailto:${t('feedbackBlock.contacts.emailValue')}`}>
                  <p>{t('feedbackBlock.contacts.emailValue')}</p>
                </a>
              </div>
            </BlockContact>
            <BlockContact>
              <img src={IconPhone} alt='iconPhone' />
              <div>
                <h5>{t('feedbackBlock.contacts.phoneTitle')}</h5>
                <p>{t('feedbackBlock.contacts.phoneValue')}</p>
              </div>
            </BlockContact>
          </LeftBlockContact>
        </LeftBox>

        {isFormSubmitted ? (
          <RightBlock>
            <h2 style={{ fontSize: '32px', marginTop: 0 }}>{t('feedbackBlock.success.title')}</h2>
            <p style={{ fontSize: '16px' }}>{t('feedbackBlock.success.message')}</p>
            <Button text={t('feedbackBlock.success.buttonRetry')} onClick={handleRetry} />
          </RightBlock>
        ) : (
          <RightBlock>
            <RightBlockHeader>{t('feedbackBlock.rightBlock.header')}</RightBlockHeader>
            <RightBlockDesc>{t('feedbackBlock.rightBlock.desc')}</RightBlockDesc>
            <RightBlockDescTwo>{t('feedbackBlock.rightBlock.descTwo')}</RightBlockDescTwo>

            <RightBlockForm onSubmit={handleFormSubmit}>
              <Input
                title={t('feedbackBlock.form.inputName')}
                type='text'
                value={name}
                placeholder={t('feedbackBlock.form.placeholderName') || ''}
                onChange={handleNameChange}
                error={inputErrors.nameError}
              />
              <InputCont>
                <Input
                  title={t('feedbackBlock.form.inputEmail')}
                  type='email'
                  value={email}
                  placeholder={t('feedbackBlock.form.placeholderEmail') || ''}
                  onChange={handleEmailChange}
                  error={inputErrors.emailError}
                />
                <InputMaskCustomBox>
                  <TitleInput>{t('feedbackBlock.form.inputPhone')}</TitleInput>
                  <InputMaskCustom
                    mask='+7 (999) 999-99-99'
                    maskChar={null}
                    value={phone}
                    placeholder={t('feedbackBlock.form.placeholderPhone') || ''}
                    onChange={handlePhoneChange}
                    className={inputErrors.phoneError ? 'input-error' : ''}
                    autoComplete='off'
                  />
                  {inputErrors.phoneError && <ErrorMessage>{inputErrors.phoneError}</ErrorMessage>}
                </InputMaskCustomBox>
              </InputCont>

              <TitleInput>{t('feedbackBlock.form.inputMessage')}</TitleInput>
              <TextareaCustom
                value={message}
                placeholder={t('feedbackBlock.form.placeholderMessage') || ''}
                onChange={handleMessageChange}
                className={inputErrors.messageError ? 'input-error' : ''}
              />
              {inputErrors.messageError && <ErrorMessage>{inputErrors.messageError}</ErrorMessage>}

              <Button text={t('feedbackBlock.form.buttonSubmit')} type='submit' />
            </RightBlockForm>
          </RightBlock>
        )}
      </FeedbackBlockBox>
    </Container>
  );
};
