import { FooterBox, InfoBox, InfoDescription, BoxRow, Copyright } from './Footer.style';
import Container from 'index.style';
import { default as LogoLendKz } from 'assets/img/LogoLendKz.svg';
import { useTranslation } from 'react-i18next';
import ScrollToTopLink from 'utils/ScrollToTopLink';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterBox>
      <Container>
        <BoxRow>
          <InfoBox className='first'>
            <img src={LogoLendKz} alt='logo' />
            <InfoDescription>{t('footer.investmentWarning')}</InfoDescription>
            <Copyright>Lend.kz © 2024 {t('footer.allRightsReserved')}</Copyright>
          </InfoBox>
          <InfoBox>
            <h4>{t('footer.sections')}</h4>
            <ul>
              <li>
                <ScrollToTopLink to='/business'>{t('footer.business')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/investors'>{t('footer.investors')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/brokers'>{t('footer.brokers')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/#projects'>{t('footer.projects')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/tariff'>{t('footer.tariff')}</ScrollToTopLink>
              </li>
            </ul>
          </InfoBox>
          <InfoBox>
            <h4>{t('footer.information')}</h4>
            <ul>
              <li>
                <ScrollToTopLink to='/user_agreement'>{t('footer.userAgreement')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/public_agreement'>{t('footer.publicOffer')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/risk_agreement'>
                  {t('footer.riskDeclaration')}
                </ScrollToTopLink>
              </li>
            </ul>
          </InfoBox>
        </BoxRow>
      </Container>
    </FooterBox>
  );
};
